html {
  box-sizing: border-box;
  font-size: 16px;
  min-height: 100%;
}

body {
  /* min-height: 100%;
  position: relative; */
  background-color: #1D191A;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.4;
  font-family: "proxima-nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  /* overflow: hidden; */
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 0.5em;
}

.demi,
.medium,
.book-oblique,
.book {
  font-family: "futura-pt", sans-serif;
}

.heavy {
  font-family: "futura-pt-bold", sans-serif;
}

.book {
  font-weight: 400;
}

.book-oblique {
  font-weight: 500;
}

.medium {
  font-weight: 600;
}

.demi, .heavy {
  font-weight: 700;
}

.pointer:hover {
  cursor: pointer;
}

.body {
  /* width: 100%;
  max-height: calc(100vh - 80px);
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden; */
}
